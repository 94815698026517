import { RatingService } from '@app/shared/services/rating.service';
import { take } from 'rxjs/operators';
import { TripService } from '@app/trips/services/trip.service';
import { ActivityCountService } from './shared/services/activity-count.service';
import { DefaultCurrencyConfig } from '@app/shared/constants/constant';
import { CommonService } from '@app/shared/services/common.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { NavigationExtras, Router } from '@angular/router';
import {
  AlertController,
  MenuController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { AppService } from './core/services/app-roles-service.service';
import { UtilService } from './core/services/util.service';
import { FirebaseDataService } from './shared/services/firebase-data.service';
import { AuthSessionService } from './core/services/auth-session.service';
import { MessageConfig } from '@app/shared/constants/constant';
import { AppUpdateService } from './core/services/app-update.service';
import { SideMenuService } from './right-menu/services/side-menu.service';
import { Events } from './shared/services/events.service';
import {
  ChatInfo,
  NotificationChat,
} from './right-menu/models/right-menu.model';
import { HardwareBackButtonService } from './shared/services/hardware-back-button.service';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { APP_CONFIG } from 'src/environments/environment';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Subscription } from 'rxjs';
import { DataService } from './shared/services/data.service';
import { Capacitor } from '@capacitor/core';
import { UserProfileService } from './profile-setup/user-profile.service';
import { SigninService } from './authentication/services/signin.service';
import { NetworkService } from './shared/services/network.service';

declare var cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [AppService],
})
export class AppComponent implements OnInit {
  public appPages;
  public isPlatformBrowser: boolean = false;
  public animatedSplash: boolean;
  public RoleID: any;
  public rightSidemenuShowContent: string = '';
  public userType: any;
  public userName: any;
  public userLogo: string;
  public appVersion: any;
  public installedVersion: any;
  public messageDisplay = MessageConfig;
  public openChat: boolean = false;
  public TripsCountValue: any;
  public totalTripsNotificationAndActivitesCount: any;
  public selectedPage: number;
  public notificationChat = new NotificationChat();
  public sidemenuShowContent: string = '';
  public chatInfoDetails: ChatInfo;
  public tripDetails: any;
  public id: any;
  public totalBadgeCount: number;
  public requestActivityCount: number;
  public disconnectSubscription: any;
  public networkAlert: any;
  public connectSubscription: any;
  public subscription: Subscription;
  public wasOffline: boolean = false;
  private initialLoad: boolean = true;
  constructor(
    public sideMenu: SideMenuService,
    private router: Router,
    private appService: AppService,
    private storage: Storage,
    private platform: Platform,
    private menuCtrl: MenuController,
    private utilService: UtilService,
    private fbDatabase: FirebaseDataService,
    private authSessionService: AuthSessionService,
    public commonService: CommonService,
    private appUpdateService: AppUpdateService,
    private alertCtrl: AlertController,
    private activityCountService: ActivityCountService,
    private events: Events,
    private tripService: TripService,
    private hardwareBackButtonService: HardwareBackButtonService,
    public toast: ToastController,
    private dataService: DataService,
    private network: Network,
    private appV: AppVersion,
    private userProfileService: UserProfileService,
    private ratingService:RatingService,
    private signinService : SigninService,
    private networkService : NetworkService
  ) {
    this.initializeApp();
    this.hardwareBackButtonService.backButtonEvent();
    this.sideMenu.closedChat.subscribe((close) => {
      if (!close) {
        this.openChat = false;
        this.rightSidemenuShowContent = '';
      }
    });
    this.commonService.getUserSessionData();
    this.sideMenuEvents();
  }

  public getTripData(SRID) {
    this.authSessionService.$userSession.pipe(take(1)).subscribe((res: any) => {
      if (res)
        switch (res.RoleID) {
          case '2':
            this.sideMenu.getClientSideMenuTrips(SRID).then((response) => { });
            break;
          case '3':
            this.sideMenu.getVendorSideMenuTrips(SRID).then((result) => { });
            break;
          default:
            return false;
        }
    });
  }
  public showWalletBalance() {
    const navigationExtras: NavigationExtras = {
      state: {
        userType: this.userType,
      },
    };
    this.router.navigate(['/wallet/'], navigationExtras);
  }

  public setCurrencyCode(userData) {
    let storeCurrByRoleId: string;
    switch (userData.RoleID) {
      case '3':
        storeCurrByRoleId = 'vendorDetails';
        break;
      case '2':
        storeCurrByRoleId = 'clientDetails';
        break;
      case '7':
        storeCurrByRoleId = 'charterDetails';
        break;
      default:
        break;
    }
    if (
      userData[storeCurrByRoleId]?.currencyCode?.currencyId != '' &&
      userData[storeCurrByRoleId]?.currencyCode?.currencyId
    ) {
      this.commonService.userCurrency =
        userData[storeCurrByRoleId].currencyCode;
    } else {
      this.commonService.userCurrency = DefaultCurrencyConfig.Currency;
    }
  }

  public sideMenuEvents() {
    // event call to load the tripdetails in the side menu.
    this.tripService.tripData.subscribe((res: any) => {
      if (res) {
        this.rightSidemenuShowContent = 'tripInformation';
        this.getTripData(res.SRID);
        this.tripDetails = res;
        this.menuCtrl.enable(true, 'end');
        this.menuCtrl.toggle('end');
      } else {
      }
    });
    this.events.subscribe('openChat', (chatDetails) => {
      switch (chatDetails.type) {
        case 'NotificationChat': {
          this.notificationChat = chatDetails;
          this.rightSidemenuShowContent = this.notificationChat.type;
          this.notificationChat.readTabActive = 'Unread';
          break;
        }
        case 'LiveChat': {
          this.chatInfoDetails = chatDetails;
          this.rightSidemenuShowContent = this.chatInfoDetails.type;
          this.sidemenuShowContent = this.chatInfoDetails.chatTabActive;
          break;
        }
        case 'Notifications': {
          this.chatInfoDetails = chatDetails;
          this.rightSidemenuShowContent = this.chatInfoDetails.type;
          this.commonService.charterAllNotificationSubject.next('Notifications')
          break;
        }

        default: {
          // statements;;
          break;
        }
      }
    });
  }
  public menuClosed(event) {
    if (this.sidemenuShowContent == 'ServiceChat') {
      this.rightSidemenuShowContent = '';
      this.menuCtrl.enable(false, 'end');
    } else {
      this.rightSidemenuShowContent =
        this.sidemenuShowContent == 'tripChats'
          ? 'tripInformation'
          : 'NotificationChat';
    }
  }

  public userInfo(res) {
    switch (res.RoleID) {
      case '2':
        this.userName = res.clientDetails.ClientName;
        this.userLogo = res.clientDetails.logo;
        this.activityCountService
          .totalBadgeCount()
          .then((operatorCount: any) => {
            this.totalBadgeCount = operatorCount;
          });
        break;
      case '3':
        this.userName = res.vendorDetails.VendorName;
        this.userLogo = res.vendorDetails.logo ?? '';
        this.activityCountService
          .fetchActivityCountFromFirebase()
          .then((res1: any) => {
            this.totalBadgeCount = res1;
          });
        this.activityCountService
          .quotesActivityCount()
          .then((res2: any) => {
            this.requestActivityCount = res2;
          });
        break;
      case '7':
        this.userName = res.charterDetails.CompanyName;
        this.userLogo = res.charterDetails.logo ?? '';
        break;
      default:
        break;
    }
  }
  public async ngOnInit() {
    // this.dataService.fetchStations();
    this.menuCtrl.enable(false, 'end');
    this.authSessionService.setRoleID();
    this.appService.setLeftMenuTabs();
    this.appService.$leftMenuItemList.subscribe((menuList) => {
      this.appPages = menuList;
      this.commonService.appPages = menuList;
    });
    this.authSessionService.activeRoute.subscribe((data: string) =>
      this.highlightUrl(data)
    );
    await this.storage.create();
    await this.storage.set('IS_SETUP_PIN_VERIFIED', false);
    this.authSessionService.$userSession.subscribe((res) => {
      if (res) {
        this.userType = res.RoleID;
        this.userInfo(res)
        this.fbDatabase.callfirebaseListData();
        this.setCurrencyCode(res);
      }
    });
    this.events.subscribe('countChange', (data) => {
      this.getActivtyCount();
    });
    this.subscription = this.userProfileService.data$.subscribe((userData) => {
      if (userData) {
        this.userInfo(userData)
      }
    });
  }
  public profile() {
    this.router.navigateByUrl('profile-setup');
  }
  public checkInternetConnection() {
    this.disconnectSubscription = this.network.onDisconnect().subscribe(async () => {
    });
    this.connectSubscription = this.network.onConnect().subscribe(() => {
      setTimeout(() => {
        if (this.network.type) {
          this.commonService.showToastCtrl(`We got a internet connection.`)
        }
      }, 300);
    });
  }
  public async onlyLogOut() {
    const alert = await this.alertCtrl.create({
      header: 'Logout!',
      subHeader: 'You want to Logout?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            alert.dismiss();
            return false;
          },
        },
        {
          text: 'Yes',
          handler: () => {
            this.utilService.showSpinnerLoader();
            this.signinService.sendLogout()
            this.authSessionService.logOut();
          },
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }
  public initializeApp() {
    this.networkService.getNetworkStatus().subscribe((isOnline) => {
      if (this.initialLoad) {
        // Set initial state without showing alert
        this.wasOffline = !isOnline;
        this.initialLoad = false;
      } else {
        if (isOnline && this.wasOffline) {
          this.commonService.showToastCtrl( MessageConfig.wifiIcon + MessageConfig.onlineAlert);
          this.wasOffline = false;
        } else if (!isOnline) {
          this.commonService.showToastCtrl( MessageConfig.wifiOfIcon +  MessageConfig.offlineAlert);
          this.wasOffline = true;
        }
      }
    });
      this.platform.ready().then(async (async) => {
      // Show the splash for an indefinite amount of time:
      if (Capacitor.isNativePlatform()) {
        this.utilService.initPush();
        this.appV.getVersionNumber().then((version) => {
        this.installedVersion = version;
        });
        this.commonService.modalCssClass = 'custom-tel-input-platform';
      } else {
        this.isPlatformBrowser = true;
        this.animatedSplash = true;
        this.installedVersion = APP_CONFIG.WebVersion;
        this.commonService.modalCssClass = 'custom-tel-input';
      }
      this.ratingService.loadRatings();
    });
    this.installedVersion = this.appUpdateService.InstalledVersion;
  }


  public getActivtyCount() {
    this.activityCountService.subscribeActivityCount();
    this.activityCountService.$vendorUnreadActivityCount.subscribe(
      (count: any) => {
        this.TripsCountValue = count != null ? count : 0;
      }
    );
  }
  public openPage(page, index?: number) {
    this.menuCtrl.enable(false, 'right');
    const navigationExtras: NavigationExtras = {
      state: {
        userType: this.userType,
      },
    };
    !this.wasOffline ? this.router.navigateByUrl(page.url, navigationExtras):this.commonService.showToastCtrl(MessageConfig.offlineAlert);
    this.selectedPage = index;
    this.commonService.selectedPage = index;
  }
  public support() {
    this.fbDatabase.supportLink().then((data: any) => {
      const ref =
        Capacitor.isNativePlatform()
          ? cordova.InAppBrowser.open(data, '_blank', 'location=yes')
          : window.open(data, '_blank');
    });
  }
  public highlightUrl(data: string) {
    this.appPages.filter((element, key) => {
      if (element.title.toLowerCase() === data.toLowerCase()) {
        this.selectedPage = key;
        this.commonService.selectedPage = key;
      }
    });
  }
  public ngOnDestroy() {
    // unsubscribe to ensure no memory leak
    this.subscription.unsubscribe();
  }
}
